<template>
  <div>
    <OnboardingLayout header-type="secondary">
      <template #header>
        <div>
          <h1 class="text-white text-5xl mb-0 leading-normal">Generate your</h1>
          <h1 class="mb-0 text-5xl leading-normal text-white">
            BrightBid <span class="text-bb-brand-green">Audit</span>
          </h1>

          <ul class="text-white text-base font-normal flex flex-col gap-2 mt-12">
            <li
              v-for="(item, index) in listItems"
              :key="index"
              class="flex items-center"
            >
              <ic-arrow-right
                :size="10"
                class="mr-4"
              />
              {{ item }}
            </li>
          </ul>
        </div>
      </template>
      <div class="my-6 min-w-full flex flex-col">
        <google-ads-integration />
      </div>
    </OnboardingLayout>
  </div>
</template>

<script>
import OnboardingLayout from '@/layouts/onboarding'
import GoogleAdsIntegration from '@/components/audit-components/GoogleAdsIntegration.vue'
import IcArrowRight from '@/components/icon/brightbid/ic-arrow-right.vue'

export default {
  name: 'AuditConnectGoogleAds',
  components: {
    GoogleAdsIntegration,
    OnboardingLayout,
    IcArrowRight,
  },
  data() {
    return {
      listItems: [
        "Quickly assess your account's overall performance.",
        'Identify low performing Ad groups, Ads & Keywords.',
        'Save budget on wasted non-performing activities.',
      ],
    }
  },
}
</script>
