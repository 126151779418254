<template>
  <div>
    <!--1. Not yet signed in -->
    <div
      v-if="!hasAvailableAccounts && !isLoading && !hasConsent"
      class="h-32 space-y-3"
    >
      <div
        v-if="$route.name == 'audit-signin'"
        class="mb-8"
      >
        <h4>Sign In to Run Audit</h4>
        <p class="font-normal mt-2">Sign in to your Google Account to connect Google Ads</p>
      </div>
      <div
        v-else
        class="mb-8"
      >
        <h4>Sign in to your Google Account to sync with Google Ads</h4>
      </div>
      <GoogleAllowAccessButton
        white
        name="Sign in with google"
        :access-scope="googleConfig.scopes.connectStore"
        @verify-token="handleClick"
      >
        <span class="text-sm">Sign in with Google</span>
      </GoogleAllowAccessButton>
      <!-- Checkbox Section -->
      <div class="flex flex-col space-y-2 mb-6 pt-6">
        <div class="flex gap-1 items-center">
          <CheckBox
            :class="{ shake: shakeCheckbox.policy }"
            input-id="policy-agreement"
            input-name="policy[agreed]"
            :value="agreePolicy"
            @input="toggleAgreement('policy')"
          />
          <p>
            I agree to BrightBid
            <router-link
              :to="{ name: 'privacy:policy' }"
              class="text-bb-brand-purple font-bold cursor-pointer"
            >
              Privacy policy
            </router-link>
          </p>
        </div>
        <div class="flex gap-1 items-start">
          <CheckBox
            :class="{ shake: shakeCheckbox.usage }"
            input-id="usage-agreement"
            input-name="usage[agreed]"
            :value="agreeUsage"
            @input="toggleAgreement('usage')"
          />
          <p>
            I agree to BrightBid using my data for third-party tools,
            <router-link
              :to="{ name: 'limited:use:policy' }"
              class="text-bb-brand-purple font-bold cursor-pointer"
            >
              read about our limited use policy.
            </router-link>
          </p>
        </div>
        <p
          v-if="showAgreementError"
          class="text-bb-error"
        >
          You must agree to the Privacy and Use Policy.
        </p>
      </div>
      <div v-if="$route.name === 'audit-signin'">
        <p class="mt-8">
          Already have a BrightBid account?
          <a
            href="/signin"
            class="text-bb-brand-purple font-bold cursor-pointer"
            >Log in to BrightBid here</a
          >.
        </p>
      </div>
    </div>
    <!--1.2 Signed in but no Gads account -->
    <div
      v-if="!hasAvailableAccounts && !isLoading && hasConsent"
      class="h-32 p-8 space-y-3"
    >
      <div
        v-if="$route.name == 'audit-signin'"
        class="mb-8"
      >
        <h4>Couldn't sign you in</h4>
        <p class="font-normal leading-tight mt-2">We couldn’t find a Google Ads account linked to your email.</p>
        <p class="font-normal">Please try logging in with a different Google account.</p>
      </div>
      <div
        v-else
        class="mb-8"
      >
        <h4>Sign in to your Google Account to sync with Google Ads</h4>
      </div>
      <GoogleAllowAccessButton
        white
        name="Sign in with google"
        :access-scope="googleConfig.scopes.connectStore"
        @verify-token="handleClick"
      >
        <span class="text-sm">Connect to another Google account</span>
      </GoogleAllowAccessButton>
      <!-- Checkbox Section -->
      <div class="flex flex-col space-y-2 pt-6">
        <div class="flex gap-1 items-center">
          <CheckBox
            :class="{ shake: shakeCheckbox.policy }"
            input-id="policy-agreement"
            input-name="policy[agreed]"
            :value="agreePolicy"
            @input="toggleAgreement('policy')"
          />
          <p>
            I agree to BrightBid
            <span
              class="text-bb-brand-purple font-bold cursor-pointer"
              @click="navigateToPolicy('privacy')"
            >
              Privacy policy
            </span>
          </p>
        </div>
        <div class="flex gap-1 items-start">
          <CheckBox
            :class="{ shake: shakeCheckbox.usage }"
            input-id="usage-agreement"
            input-name="usage[agreed]"
            :value="agreeUsage"
            @input="toggleAgreement('usage')"
          />
          <p>
            I agree to BrightBid using my data for third-party tools,
            <router-link
              :to="{ name: 'limited:use:policy' }"
              class="text-bb-brand-purple font-bold cursor-pointer"
            >
              read about our limited use policy.
            </router-link>
          </p>
        </div>
        <p
          v-if="showAgreementError"
          class="text-bb-error"
        >
          You must agree to the Privacy and Use Policy.
        </p>
      </div>
      <div v-if="$route.name === 'audit-signin'">
        <p class="mt-8">
          Already have a BrightBid account?
          <a
            href="/signin"
            class="text-bb-brand-purple font-bold cursor-pointer"
            >Log in to BrightBid here</a
          >.
        </p>
      </div>
    </div>
    <!--2. Loading-->
    <div
      v-if="isLoading"
      class="min-w-full flex justify-center"
    >
      <BrightBidLoader
        :size="120"
        class="mx-auto"
      />
    </div>

    <!--3. Display a list of Google Accounts-->
    <div
      v-if="hasAvailableAccounts && !isLoading && hasConsent"
      class="py-4 px-6 space-y-6"
    >
      <div class="text-sm">
        <div v-if="accountsList.length > 0">
          <div v-if="$route.name == 'audit-signin'">
            <div class="flex flex-row gap-2.5 mb-8">
              <img
                class="h-8"
                src="/content/images/g-ads-icon.svg"
                alt="G_ADS_SEARCH"
              />
              <p class="h4 text-bb-text-headers font-medium">Connect to your Google Ads account</p>
            </div>
            <p>
              You are signed in as <span class="font-bold">{{ auditUser.email }}</span>
            </p>
            <p>Accounts connected to your email:</p>
          </div>
          <p v-else>Accounts connected to your Google account:</p>
          <text-input
            v-model="searchQuery"
            :disabled="isLoading"
            input-name="search-query"
            input-id="search-query"
            input-type="text"
            placeholder="Search for an account"
          />
        </div>
        <p v-else>No accounts found</p>
      </div>
      <div class="text-sm border border-neutral-100 divide-y divide-neutral-100">
        <div
          v-for="(googleAdsAccount, index) in paginationRows"
          :key="index"
          class="flex justify-between items-center px-6 h-16"
        >
          <p>
            {{ googleAdsAccount.name }}&ensp;<span class="font-bold">{{ googleAdsAccount.readable_id }}</span>
          </p>
          <MergeButtonSquare
            v-if="connectedAccount.readable_id !== googleAdsAccount.readable_id"
            button-type="google"
            @click="connectGoogleAdsAccount(googleAdsAccount)"
          >
            Connect
          </MergeButtonSquare>
          <div
            v-else
            class="bg-bb-success-bg text-bb-success-text rounded-full py-1 px-3 m-3"
          >
            Connected
          </div>
        </div>

        <div
          v-if="searchQuery && totalItems <= 0"
          class="flex gap-2 items-center px-12 h-16 bg-neutral-0 cursor-pointer"
        >
          <p class="font-medium">No accounts found</p>
        </div>
        <div class="flex gap-2 items-center px-12 h-16 bg-neutral-0 cursor-pointer">
          <ic-plus
            class="text-bb-brand-purple"
            size="16"
          />
          <p class="font-medium text-bb-brand-purple">Create a new Google Ads account</p>
        </div>
      </div>
      <div v-if="rowsPerPage < accountsList.length">
        <pagination
          :limit="rowsPerPage"
          :page="currentPage"
          :max="totalItems"
          @onChange="changePage"
        />
      </div>

      <p
        v-if="!connectedAccount.id"
        class="text-sm"
      >
        Cant’s find your Google Ads account? You might need to log in with a different Google account
      </p>
      <p
        v-else
        class="text-sm"
      >
        Would you prefer selecting a different account?
      </p>
      <GoogleAllowAccessButton
        white
        name="Connect to another Google account"
        :access-scope="googleConfig.scopes.connectStore"
        @verify-token="$emit('verify-token', $event)"
      >
        <span class="text-sm">Connect to another Google account</span>
      </GoogleAllowAccessButton>
      <div class="grid grid-cols-1 mx-13.5">
        <MergeButtonRound
          class="w-full"
          button-type="primary"
          :disabled="!auditUser.googleCustomerId"
          @click="goToAuditReport"
        >
          <p>Continue</p>
          <template #right-icon> <IcChevron direction="right" /> </template>
        </MergeButtonRound>
      </div>
    </div>
  </div>
</template>

<script>
import IcPlus from '@/components/icon/ic-plus'
import IcChevron from '@/components/icon/ic-chevron'
import Pagination from '@/components/table/Pagination'
import googleConfig from '@/configs/google-client'
import { mapGetters } from 'vuex'
import GoogleAllowAccessButton from '@/components/btn/Google/GoogleAllowAccessButton.vue'
import TextInput from '@/components/input/brightbid/TextInput.vue'
import BrightBidLoader from '@/components/loader/BrightbidLoader.vue'
import CheckBox from '@/components/input/brightbid/CheckBox'

export default {
  name: 'BaseGoogleAdsIntegration',
  components: {
    GoogleAllowAccessButton,
    IcPlus,
    Pagination,
    TextInput,
    BrightBidLoader,
    IcChevron,
    CheckBox,
  },
  props: {
    accountsList: {
      type: Array,
      default: function () {
        return []
      },
    },
    hasConsent: {
      type: Boolean,
      default: false,
    },
    connectedAccount: {
      type: Object,
      default: function () {
        return {}
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      agreePolicy: true,
      agreeUsage: true,
      showAgreementError: false,
      shakeCheckbox: {
        policy: false,
        usage: false,
      },
      searchQuery: '',
      currentPage: 1,
      rowsPerPage: 5,
      totalItems: 0,
      googleConfig: googleConfig,
    }
  },
  computed: {
    ...mapGetters({
      account: 'account/getAccount',
      user: 'auth/user',
      auditUser: 'auth/userAuditGoogleCredentials',
    }),
    hasAvailableAccounts() {
      return this.accountsList.length > 0
    },
    paginationRows() {
      if (!this.accountsList) return []

      let filteredAccounts = this.accountsList

      if (this.searchQuery) {
        const searchQueryLower = this.searchQuery.toLowerCase()
        filteredAccounts = this.accountsList.filter(
          account =>
            (account.name && account.name.toLowerCase().includes(searchQueryLower)) ||
            (account.readable_id && account.readable_id.toLowerCase().includes(searchQueryLower)),
        )
      }
      this.updatePagination(filteredAccounts.length)
      return filteredAccounts.slice((this.currentPage - 1) * this.rowsPerPage, this.currentPage * this.rowsPerPage)
    },
  },
  watch: {
    accountsList: {
      handler() {
        this.initializeAccountList()
      },
      immediate: true,
    },
    searchQuery() {
      this.currentPage = 1 // Reset to page 1 on new search
    },
  },
  methods: {
    initializeAccountList() {
      if (this.accountsList.length > 0) {
        this.currentPage = 1
        this.filterAccounts()
      }
    },
    changePage(paginationData) {
      this.currentPage = paginationData.current_page
      this.filterAccounts()
    },
    filterAccounts() {
      this.updatePagination(this.accountsList.length)
    },
    updatePagination(totalItems) {
      this.totalItems = totalItems
    },
    async connectGoogleAdsAccount(googleAdsAccount) {
      this.$emit('google-ads-account-connected', googleAdsAccount)
    },
    toggleAgreement(type) {
      this.shakeCheckbox[type] = false
      this.showAgreementError = false
      if (type === 'policy') {
        this.agreePolicy = !this.agreePolicy
      } else if (type === 'usage') {
        this.agreeUsage = !this.agreeUsage
      }

      if (!this.agreePolicy || !this.agreeUsage) {
        this.shakeCheckbox[type] = false
      }
    },
    navigateToPolicy(type) {
      this.$router.push({ name: type })
    },
    goToAuditReport() {
      this.$router.push({ name: 'audit-report' })
    },
    handleClick(event) {
      // Check if both checkboxes are checked
      if (this.agreePolicy && this.agreeUsage) {
        // Emit the 'verify-token' event if both checkboxes are checked
        this.$emit('verify-token', event)
      } else {
        // If not, throw error
        this.showAgreementError = true
        if (!this.agreePolicy) {
          this.shakeCheckbox.policy = true
        }
        if (!this.agreeUsage) {
          this.shakeCheckbox.usage = true
        }

        setTimeout(() => {
          this.shakeCheckbox.policy = false
          this.shakeCheckbox.usage = false
        }, 1000)
      }
    },
    shakeCheckboxNow(checkboxName) {
      if (!Object.prototype.hasOwnProperty.call(this.shakeCheckbox, checkboxName)) {
        return
      }
      this.shakeCheckbox[checkboxName] = true
      setTimeout(() => (this.shakeCheckbox[checkboxName] = false), 1000)
    },
  },
}
</script>
