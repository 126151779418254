<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 10L5.5 5L0.5 0V10Z"
      fill="#6366FA"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcArrowRight',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
  },
}
</script>
